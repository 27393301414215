import React  from "react";

function LoadingSpinner() {

  return (
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
  );
}
export default LoadingSpinner;
