import React, {Component} from 'react'
import Layout from "../components/layout.js"
import {navigate} from "gatsby"
import ReCAPTCHA from 'react-google-recaptcha';
import Select from 'react-select';
import LoadingSpinner from "../components/loading-spinner";


const selections = [
    {label: "I have a general question.", value: 1},
    {label: "I am looking for technical support.", value: 2},
    {label: "I want a demo account.", value: 3},
];


const isBrowser = typeof window !== "undefined"

function isLocalhost() {
    if (!isBrowser) {
        return false
    }
    switch (window.location.hostname) {
        case 'localhost':
        case '::1':
        case '127.0.0.1':
            return true
    }
    return false
}


export default class Contact extends Component {
    constructor(props) {
        super(props);

        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            message: "",
            firstNameValidation: true,         //am anfang true, damit der rote text nicht am Amfang gleich gezeigt wird
            lastNameValidation: true,
            emailValidation: true,
            messageValidation: true,
            validationOK: false,
            submitEvent: null,
            triggerReCAPTCHA: false,
            captchaValue: "",
            serverError: false,
            sending: false,
            currentSelectionValue: 0,
            docker_env: null
        }

        this.submitMail = this.submitMail.bind(this)
        this.handleFirstName = this.handleFirstName.bind(this)
        this.handleLastName = this.handleLastName.bind(this)
        this.handleCompany = this.handleCompany.bind(this)
        this.handleEmail = this.handleEmail.bind(this)
        this.handleMessage = this.handleMessage.bind(this)
        this.sendMail = this.sendMail.bind(this)
        this.onCompleteCaptcha = this.onCompleteCaptcha.bind(this)
        this.validateName = this.validateName.bind(this)
        this.onSelectionChange = this.onSelectionChange.bind(this)
        this.clearValues = this.clearValues.bind(this)
        this.setAllValidationsInvalid = this.setAllValidationsInvalid.bind(this)
        this.fetchEnv = this.fetchEnv.bind(this)
        this.getMailerSubdomain = this.getMailerSubdomain.bind(this)
        this.getMailerPrefix = this.getMailerPrefix.bind(this)
        this.getReCAPTCHA_SITEKEY = this.getReCAPTCHA_SITEKEY.bind(this)

        this.fetchEnv()
    }

    fetchEnv() {
        if (!isBrowser || isLocalhost()) {
            return
        }
        fetch("/env.json")
            .then(response => response.json())
            .then(json => {
                console.log("successfully fetched ENV JSON: ", json)
                this.setState({docker_env: json})
            })
            .catch(exception => {
                console.error('failed to load /env.json:', exception)
                this.setState({docker_env: {}})
            })
    }

    getMailerSubdomain() {
        if (this.state.docker_env["MAILER_DOMAIN"] && this.state.docker_env["MAILER_DOMAIN"].length > 0) {
            return 'https://' + this.state.docker_env["MAILER_DOMAIN"]
        }
        if (window.location.hostname.startsWith("www.")) {
            return 'https://mailer' + window.location.hostname.substr(4)
        }
        return 'https://mailer.' + window.location.hostname
    }

    getMailerPrefix() {
        const result = isLocalhost() ? 'http://localhost:8080' : this.getMailerSubdomain()
        console.log("defining mailer prefix as: ", result)
        return result
    }

    getReCAPTCHA_SITEKEY() {
        if (this.state.docker_env["RECAPTCHA_SITEKEY"] && this.state.docker_env["RECAPTCHA_SITEKEY"].length > 0) {
            return this.state.docker_env["RECAPTCHA_SITEKEY"]
        }
        console.log("could not find ReCAPTCHA SiteKey, using default")
        return '6LfgcNQbAAAAAAYRANzYykH7dn9-aFLppnQN4Yh4'
    }

    validateName(name) {
        if (this.state.serverError) this.setState({serverError: false})      //When the user changes something, error should be chancel, so false
        return !String(name).match(/([\*\.\>\<\\\{\[\]\}\(\)\$\&\/\_\:\;\,\#\+\~\!\"\´\`\|])/)
    }

    handleFirstName(event) {
        var validation = event.target.value.length && this.validateName(event.target.value) && event.target.value.length <= 30
        this.setState({firstNameValidation: validation})
        this.setState({firstName: event.target.value})
    }

    handleLastName(event) {
        var validation = event.target.value.length && this.validateName(event.target.value) && event.target.value.length <= 30
        this.setState({lastNameValidation: validation})
        this.setState({lastName: event.target.value})
    }

    handleCompany(event) {
        var validation = event.target.value.length && this.validateName(event.target.value) && event.target.value.length < 255
        this.setState({companyValidation: validation})
        this.setState({company: event.target.value})
    }

    handleEmail(event) {
        this.setState({emailValidation: event.target.value.length})
        this.setState({email: event.target.value})
    }

    handleMessage(event) {
        this.setState({messageValidation: event.target.value.length})
        this.setState({message: event.target.value})
    }

    sendMail() {
        this.setState({sending: true})
        fetch(this.getMailerPrefix() + "/createVerificationLink",
            {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'G-reCaptcha-Val': this.state.captchaValue
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: JSON.stringify(
                    {
                        firstName: this.state.firstName,
                        lastName: this.state.lastName,
                        company: this.state.company,
                        email: this.state.email,
                        message: this.state.message,
                        reason: this.state.currentSelectionValue
                    }
                ),
            }
        )
            .then(response => {
                console.log(response.status)
                this.setState({sending: false})
                if (response.status == 200) {
                    console.log("Request went through")
                    this.setState({serverError: false})
                } else {
                    console.log("Something went wrong in the request")
                    this.setState({serverError: true})
                }
                return response.text()
            })
            .then(data => {
                if (data == "Ok-Verified") {
                    navigate("/contactcomplete")
                } else {
                    navigate("/verificationsent")
                }
            })
            .catch(exception => {
                console.log("Something went wrong in the request: " + exception)
                this.setState({serverError: true})
            })
    }

    sendDemoRequest() {
        this.setState({sending: true})
        fetch(this.getMailerPrefix() + "/createDemoRequest",
            {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'G-reCaptcha-Val': this.state.captchaValue
                },
                body: JSON.stringify(
                    {
                        firstName: this.state.firstName,
                        lastName: this.state.lastName,
                        company: this.state.company,
                        email: this.state.email,
                        message: this.state.message,
                        reason: this.state.currentSelectionValue
                    }
                ),
            }
        )
            .then(response => {
                console.log(response.status)
                this.setState({sending: false})
                if (response.status == 200) {
                    console.log("Request went through")
                    navigate("/demorequested")
                    this.setState({serverError: false})
                } else {
                    console.log("Something went wrong in the request")
                    this.setState({serverError: true})
                }
            })
            .catch(exception => {
                console.log("Something went wrong in the request: " + exception)
                this.setState({serverError: true})
            })
    }

    setAllValidationsInvalid() {
        this.setState({firstNameValidation: this.state.firstName.length})
        this.setState({lastNameValidation: this.state.lastName.length})
        this.setState({companyValidation: this.state.company.length})
        this.setState({emailValidation: this.state.email.length})
        this.setState({messageValidation: this.state.message.length})
    }

    async submitMail(event) {
        event.preventDefault()
        console.log("Submitting")

        if (this.state.currentSelectionValue == 0) {
            return
        } else if (this.state.currentSelectionValue == 3) {
            await this.setState({message: "demo request"})
        }

        if (this.state.firstNameValidation && this.state.firstName != ""
            && this.state.lastNameValidation && this.state.lastName != ""
            && this.state.companyValidation && this.state.company != ""
            && this.state.emailValidation && this.state.email != ""
            && this.state.messageValidation && this.state.message != ""
            && this.state.captchaValue.length
        ) {
            if (this.state.currentSelectionValue == 3) {
                this.sendDemoRequest()
            } else {
                this.sendMail()
            }
        } else {
            console.log("Validation error")
            this.setAllValidationsInvalid()
        }

    }

    clearValues() {
        this.setState({firstName: ""})
        this.setState({lastName: ""})
        this.setState({company: ""})
        this.setState({email: ""})
        this.setState({message: ""})
    }

    onCompleteCaptcha(val) {
        this.setState({captchaValue: val})
    }

    onSelectionChange(selection) {
        this.setState({currentSelectionValue: selection.value})
        this.clearValues();
    }

    render() {
        if (this.state.docker_env == null || !isBrowser) {
            return (
                <Layout>
                    <div className="w-full max-w-3xl p-4 mx-auto my-auto text-black text-center py-32">
                        <LoadingSpinner/>
                    </div>
                </Layout>
            );
        }
        return (
            <Layout>
                <div className="w-full max-w-3xl p-4 mx-auto text-black text-center py-32">
                    <p className="text-4xl mx-auto text-left mb-4">Contact us</p>
                    <div className="h-1 mb-4 mx-auto gradient opacity-25 rounded"></div>
                    <p className="text-xl mx-auto text-left mb-4">What would you like to contact us about?</p>
                    <div className="text-left mb-2">
                        <Select options={selections} onChange={this.onSelectionChange}/>
                    </div>
                    {this.state.currentSelectionValue == 0 &&
                    <p className="text-red-800 text-md italic text-left mb-6">Please select a reason</p>
                    }
                    {this.state.currentSelectionValue == -3 &&
                    <p className="text-gray-500 text-md italic text-left mb-6">The demo allows you to save <b>20
                        Emails</b> to a ECM system for the duration of <b>90 days,</b> after which you will receive an
                        email.</p>
                    }
                    <div className="text-2xl mx-auto text-left mt-4">
                        <form onSubmit={this.submitMail}>
                            <div className="flex flex-wrap -mx-3 mb-6">
                                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label
                                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                        htmlFor="firstName">
                                        First Name
                                    </label>
                                    <input
                                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-400 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                                        id="firstName" type="text"
                                        placeholder="First Name"
                                        value={this.state.firstName}
                                        onChange={this.handleFirstName}/>
                                    {!this.state.firstNameValidation &&
                                    <p className="text-red-500 text-xs italic">Please enter a valid first name</p>
                                    }
                                </div>
                                <div className="w-full md:w-1/2 px-3">
                                    <label
                                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                        htmlFor="lastName">
                                        Last Name
                                    </label>
                                    <input
                                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                                        id="lastName" type="text"
                                        placeholder="Last Name"
                                        value={this.state.lastName}
                                        onChange={this.handleLastName}/>
                                    {!this.state.lastNameValidation &&
                                    <p className="text-red-500 text-xs italic">Please enter a valid last name</p>
                                    }
                                </div>
                            </div>
                            <div className="flex flex-wrap -mx-3 mb-6">
                                <div className="w-full px-3 mb-6 md:mb-0">
                                    <label
                                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                        htmlFor="company">
                                        Company Name
                                    </label>
                                    <input
                                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-400 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                                        id="company" type="text"
                                        placeholder="Company"
                                        value={this.state.company}
                                        onChange={this.handleCompany}/>
                                    {!this.state.companyValidation &&
                                    <p className="text-red-500 text-xs italic">Please enter the name of your company</p>
                                    }
                                </div>
                            </div>
                            <div className="flex flex-wrap -mx-3 mb-6">
                                <div className="w-full px-3 mb-6 md:mb-0">
                                    <label
                                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                        htmlFor="email">
                                        Email
                                    </label>
                                    <input
                                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-400 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                                        id="email" type="text"
                                        placeholder="Email"
                                        value={this.state.email}
                                        onChange={this.handleEmail}/>
                                    {!this.state.emailValidation &&
                                    <p className="text-red-500 text-xs italic">Please choose a valid email.</p>
                                    }
                                    {this.state.currentSelectionValue == 3 &&
                                    <p className="text-red-500 text-xs italic">This mail address will be activated for
                                        the demo.</p>
                                    }
                                </div>
                            </div>
                            {this.state.currentSelectionValue != 3 &&
                            <div className="flex flex-wrap -mx-3 mb-6">
                                <div className="w-full px-3 mb-6 md:mb-0">
                                    <label
                                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                        htmlFor="message">
                                        Message
                                    </label>
                                    <textarea rows="4" cols="50"
                                              className="appearance-none block h-44 w-full bg-gray-200 text-gray-800 border border-gray-400 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                                              id="message"
                                              placeholder="Message"
                                              value={this.state.message}
                                              onChange={this.handleMessage}/>
                                    {!this.state.messageValidation &&
                                    <p className="text-red-500 text-xs italic">Cannot send a empty message</p>
                                    }
                                </div>
                            </div>
                            }
                            <div className="flex flex-wrap">
                                <ReCAPTCHA
                                    sitekey={this.getReCAPTCHA_SITEKEY()}
                                    onChange={this.onCompleteCaptcha}
                                />
                            </div>
                            <p className="text-gray-800 text-xs italic mb-6">Complete the CAPTCHA before sending.</p>

                            <div className="flex flex-wrap mb-6">
                                <button className="bg-blue-500 rounded px-10 py-2 buttonHover" type="submit"
                                        value="Submit">
                                    {this.state.sending ?
                                        <div className="flex flex-row mx-auto">
                                            <div className="loader mt-1 mr-3 h-24"></div>
                                            <div>Sending...</div>
                                        </div>
                                        :
                                        <div>
                                            {this.state.currentSelectionValue == 3 ?
                                                "Request a demo"
                                                :
                                                "Send"
                                            }
                                        </div>
                                    }
                                </button>
                            </div>

                            {this.state.serverError &&
                            <div className="flex flex-wrap text-red mb-6">
                                <p className="text-red-800 text-xs italic">Something went wrong. Try again.</p>
                            </div>
                            }

                        </form>


                    </div>
                </div>
            </Layout>
        )
    }
}

