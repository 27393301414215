import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";

import gmail2herotextIcon from "../images/Gmail2ECM.png";
import { navigate } from "gatsby"

const navigateToHome = () => navigate('/')
const navigateToImpressum = () => navigate('/impressum')
const navigateToContact = () => navigate('/contact')
const navigateToPrivacy = () => navigate('/privacy')
const navigateToTermsAndCondition = () => navigate('/termsandconditions')

export default class Layout extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      navBarTransparent:""
    }

  }

  componentDidMount(){
  }

  componentWillUnmount(){
  }

 

  render(){
    return (
      <div className="leading-normal tracking-normal text-white">
          {/* Navigation */}
          <nav id="header"
               className="fixed w-full z-30 top-0 text-white shadow bg-white">

            <div className="max-w-4/5 container mx-auto flex flex-wrap items-center justify-between mt-0 py-2">
              <div className="pl-4 flex w-1/2 items-center">
              <img src={gmail2herotextIcon} className="h-12" />
              <div className="toggleColour no-underline hover:no-underline font-bold text-xl lg:text-3xl text-gray-800"  href="#">
                  <button className="pl-2 font-bold" onClick={navigateToHome}>
                    Gmail2ECM
                  </button>
              </div>
              </div>

            {/*
              <div className="flex items-center w-1/2 hidden lg:block mt-2 lg:mt-0 bg-white text-black p-4 lg:p-0 z-20" id="nav-content">
                <ul className="list-reset lg:flex justify-end flex-1 items-center">
                  <li className="mr-10">
                    <CustomLink href="impressum" textSize="text-2xl">Impressum</CustomLink>
                  </li>
                  <li className="mr-10">
                    <CustomLink href="privacy" textSize="text-2xl">Contact Us</CustomLink>
                  </li>
                </ul>
              </div>
            */}
            </div>
            <hr className="border-b border-gray-100 opacity-25 my-0 py-0" />
          </nav>

          {this.props.children}


          {/* Footer */}
          <footer className="bg-white border-t pt-6 pb-10">
            <div className="container mx-auto px-8">
                <div className="w-full text-center flex flex-col content-center md:flex-row py-2">

                    <div className="flex-1 flex flex-col flex-wrap content-center">
                      <div className="flex-1">
                        <img src={gmail2herotextIcon} className="flex-1 h-32"></img>
                      </div>
                    </div>
                    <div className="flex-1 flex flex-row content-center mt-4">
                        <div className="flex-1 my-auto no-underline text-gray-800 hover:text-orange-500" onClick={navigateToPrivacy}>
                            Privacy
                        </div>
                        <div className="flex-1 my-auto no-underline text-gray-800 hover:text-orange-500" onClick={navigateToImpressum}>
                            Impressum
                        </div>
                        <div className="flex-1 my-auto no-underline text-gray-800 hover:text-orange-500" onClick={navigateToContact}>
                            Contact Us
                        </div>
                    </div>
                </div>
            </div>
          </footer>
      </div>
    );
  }
  
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

